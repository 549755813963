main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681F2;
}

@media screen and (min-width: 300px) and (max-width: 480px) and (orientation: portrait), screen and (min-height: 300px) and (max-height: 480px) and (orientation: landscape) {
  .ec-modal {
    width: 300px !important;
    padding: 10px;
  }
}
.ec-modal {
  background: #fff;
  position: relative;
  padding: 32px 32px 24px 32px;
  border: 1px solid rgba(186,186,204,.2);
  -webkit-box-shadow: 0px 8px 24px rgba(35,35,51,.1);
  box-shadow: 0px 8px 24px rgba(35,35,51,.1);
  border-radius: 12px;
  width: 480px;
  color: #232333;
}
.ec-modal .ec-modal-body {
  word-break: break-word;
}
.ec-modal .ec-modal-body-title {
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  min-height: 16px;
}
.ec-modal .ec-modal-body-content {
  font-size: 14px;
  padding: 8px 0px 0px 0px;
  min-height: 38px;
  max-height: 400px;
  overflow: auto;
}
.ec-modal .ec-modal-footer {
  padding: 26px 0 0 0;
}
.ec-modal-footer-default-actions {
  display: flex;
  justify-content: flex-end;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  -webkit-transition: opacity 150ms ease-out;
  transition: opacity 150ms ease-out;
}
.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99;
}
.ec-btn {
  position: relative;
  white-space: nowrap;
  background-image: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  border-radius: 8px;
  height: 32px;
  padding: 0 16px;
  line-height: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 2px solid rgba(0,0,0,0);
  outline-offset: 1px;
  -webkit-transition: .2s all ease-in;
  transition: .2s all ease-in;
  background: #fff;
  border: 1px solid #bababa;
  color: #232333;
  font-size: 14px;
}
.ec-btn__outline--blue {
  outline: 2px solid rgba(0,0,0,0);
  outline-offset: 2px;
  -webkit-transition: .2s all ease-in;
  transition: .2s all ease-in;
}
.ec-modal .ec-modal-footer-default .ec-btn {
  margin-left: 12px;
}
.ec-modal .ec-btn--primary {
  font-weight: bold;
}
.ec-btn.ec-btn-legacy {
  border-radius: 8px;
  padding: 6px 20px;
}
.ec-btn--primary {
  background: #244092;
  color: #fff;
  border: none;
  margin-right: 10px;
}