body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  min-width: 0 !important;
}

* {
  user-select: none !important;
}


.preview-root > .preview-new-flow ~ .page-footer {
  display: none !important;
}

.preview-root .preview-exit-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.preview-root .preview-exit-btn svg {
  width: 30px;
}

.preview-root > .preview-new-flow .preview-meeting-info .preview-agreement {
  white-space: pre-wrap;
  font-size: 15px;
  text-align: center;
}

.preview-root > .preview-new-flow .preview-meeting-info .preview-agreement b {
  font-size: 16px;
}

.preview-root > .preview-new-flow .preview-meeting-info .zm-btn.preview-join-button {
  background-color: #244092;
}

.preview-root > .preview-new-flow .preview-meeting-info .zm-btn.preview-join-button:hover, .preview-root > .preview-new-flow .preview-meeting-info .zm-btn.preview-join-button:active {
  background-color: #122369;
}

#preview-av-video-preview-room.preview-video-mobile .preview-username {
  font-size: 16px;
}

.mobile-waiting-room.on-hold-main.mobile-on-hold-main .page-footer, .mobile-waiting-room.on-hold-main.mobile-on-hold-main .wr-exit-btn {
  display: none;
}

@media screen and (min-height: 300px) and (max-height: 480px) and (orientation: landscape) {
  
  .preview-new-flow .preview-new-flow-content.vertical-container {
    flex-direction: row !important;
    -webkit-box-orient: unset;
  }

}

